<style>
.dialog-info-label {
  display: inline-block;
  width: 340px;
}
</style>

<template>
  <div class="app-container">
    
    <el-tabs 
		v-model="activeNames"
		@tab-click="handleClick"
		v-loading="loading"
		value="2">
      <el-tab-pane label="Send Mail Clan" name="1">
        <el-form ref="form" :model="mail" label-width="120px">
          <!-- {{debugLog}} -->
          <el-form-item label="From">
            <b>{{ mail.postedByUser }}</b>
          </el-form-item>

          <el-form-item label="To Clan ID">
            <el-input
              v-model="mail.mailBoxID"
              class="edit-input"
              type="textarea"
            />
          </el-form-item>

          <el-form-item label="Title">
            <el-input
              v-model="mail.title"
              class="edit-input"
              placeholder='Input=text or Input=${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Content">
            <el-input
              type="textarea"
              v-model="mail.content"
              class="edit-input"
              placeholder='Input=text or Input= ${ResourceString_ID + @param1;param2...} or Input={"EN": "hello123","VI": "xin chao123",...}'
            />
          </el-form-item>

          <el-form-item label="Time Live(s)">
            <el-input
              type="textarea"
              v-model="mail.mailTimeLive"
              class="edit-input"
              placeholder='Input=number of second'
            />
          </el-form-item>

          <el-form-item label="Reward">
          </el-form-item>
          <div style="padding-left: 120px; padding-bottom: 20px">
            <add-reward v-model="mail.mailRewards" />
          </div>

          <el-form-item label="User Filters">
            <!--{{ mailgroup.user_filters }}-->
          </el-form-item>
          <div style="padding-left: 120px; padding-bottom: 20px">
            <user-filter
              v-on:CallbackFillterData="CallbackFillterData"
            ></user-filter>
          </div>

          <el-form-item>
            <el-button type="primary" @click="onSendMail">Send</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddReward from "@/components/AddReward";
import UserFilter from "@/components/CompUserFilter";
import EditRewardList from "@/components/CompRewardList";
import { MessageBox, Message, Notification, InputNumber } from "element-ui";
import { isStringEmpty } from "@/utils/validate";

import store from "@/store";

import apiClan from "@/api/clan.js";
import { SERVER_RESPONSE_CODE} from "@/defines/constants.js";

export default {
  name: "SendMail",
  components: { AddReward, UserFilter },
  data() {
    return {
		loading: false,
		activeNames: "1",
		mail: {
			postedByUser: store.getters.email,
			mailBoxID: "",
			filters: [],
			mailTimeLive: 86400,
			title: "",
			content: "",
			mailRewards: [],
		},

		rewardArray: []
    };
  },
  created() {
  },
  methods: {  
	CallbackFillterData(filterData) {
      this.mail.filters = [];
      this.mail.filters = filterData;
      return;
    },

	async onSendMail() {
		if (isStringEmpty(this.mail.mailBoxID) ||
		this.mail.mailBoxID.charAt(0) === ":"
		) {
			Message({
			message: "Receiver id is empty",
			type: "error",
			duration: 5 * 1000,
			});
			return;
		}

		if (isStringEmpty(this.mail.title)) {
			Message({
			message: "Title is empty",
			type: "error",
			duration: 5 * 1000,
			});
			return;
		}

		if (isStringEmpty(this.mail.content)) {
			Message({
			message: "Content is empty",
			type: "error",
			duration: 5 * 1000,
			});
			return;
		}

		let messageBoxData = await MessageBox.confirm(
			"Do you confirm send mail to user?",
			"Confirm send mail",
			{
			confirmButtonText: "Send",
			cancelButtonText: "Cancel",
			type: "warning",
			}
		)

		if(messageBoxData != 'confirm') {
			console.log('Cancel Send Mail');
			return;
		}

		this.loading = true;

		// Send mail
		console.log("Process Send Mail");
		this.rewardArray = [];
		this.mail.mailRewards.forEach(reward => {
			var strReward = "";
			if(reward.code.includes("RESOURCE_MAIL_CLAN_ALL_MEMBER")){
				strReward = reward.code.replace("{0}", reward.value);
			} else {
				strReward = reward.code + "." + reward.value;
			}

			console.log(strReward);
			this.rewardArray.push(strReward);
		});

		let mailData = {
			mailBoxID: this.mail.mailBoxID,
			postedByUser: "System@ZH",
			filters: this.mail.filters,
			mailTimeLive: this.mail.mailTimeLive,
			title: this.mail.title,
			content: this.mail.content,
			mailRewards: this.rewardArray
		};

		let returnData = await apiClan.sendMail(mailData);
		if (returnData !== null &&
			returnData !== undefined &&
			returnData.code !== null &&
			returnData.code !== undefined) {
				if(returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {
					Message({
					message: "Send Mail Success",
					type: "success",
					duration: 5 * 1000,
					});
				} else {
					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
						});
				}
			}
		

		this.loading = false;
		this.mail.mailBoxID = "";
    },

    handleClick() {},
  },
};
</script>
